import request from '@/utils/request'
// 个人中心获取用户信息
export function findUserInfoByAccountNum(accountNum, schoolCode, dataSource) {
  return request({
    url: 'pay/web/personalCenter/findUserInfoByAccountNum',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'accountNum': accountNum,
      'schoolCode': schoolCode,
      'dataSource': dataSource
    }
  })
}
// 验证原始密码是否正确
export function checkOldPasswd(accountNum, schoolCode, dataSource, password) {
  return request({
    url: 'pay/web/personalCenter/checkOldPasswd',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'accountNum': accountNum,
      'schoolCode': schoolCode,
      'dataSource': dataSource,
      'password': password
    }
  })
}
// 重置密码   (修改密码)
export function resetPass(userId, newPass) {
  return request({
    url: 'pay/web/personalCenter/resetPass',
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'userId': userId,
      'newPass': newPass
    }
  })
}

// 微信账号绑定
export function boundUserAccount(data) {
  return request({
    url: '/pay/login/boundUserAccount',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: data
  })
}

// 微信账号绑定
export function queryUserNameAndClass(data) {
  return request({
    url: '/pay/login/queryUserNameAndClass',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: data
  })
}

// 点金计划
export function queryWeXinOrderTrade(data) {
  return request({
    url: 'pay/open/weChat/queryWeXinOrderTrade',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: data
  })
}
