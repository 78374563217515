import request from '@/utils/request'
// 查询收费模型列表
export function pageModelInfoList(pageCurrent, pageSize, status, schoolCode) {
  return request({
    url: 'pay/pay/model/pageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'pageCurrent': pageCurrent,
      'pageSize': pageSize,
      'status': status,
      'schoolCode': schoolCode
    }
  })
}
// 上传项目图标
export function uploadHeadImg(rawFile) {
  const data = new FormData()
  data.append('file', rawFile)
  return request({
    url: 'common/com/img/userHeadImgUpload ',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}
export function applyPro(data) {
  return request({
    url: 'pay/pay/project/apply/applyPro',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function getProjectVoById(projectId) {
  return request({
    url: 'pay/project/getProjectVoById/' + projectId,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function applyProEmit(data) {
  return request({
    url: 'pay/pay/project/apply/applyProEmit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
