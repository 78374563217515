import request from '@/utils/request'
export function getTuitionAndDormList(idserial, proId) {
  return request({
    url: `pay/web/tuitionAndDorm/getTuitionAndDormList/${idserial}/${proId}`,
    method: 'get'
  })
}
export function createOrder(data) {
  return request({
    url: 'pay/web/tuitionAndDorm/createOrder',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function getTuitionAndDormListForYingxin(data) {
  return request({
    url: 'pay/tutionDorm/openTuitionAndDormPay',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function nextStepGoToGreen(data) {
  return request({
    url: 'pay/openapi/pay/greenChannelRemissionReceipts',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
